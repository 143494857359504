import React, { useLayoutEffect, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import { graphql, Link } from "gatsby"
import { navigate } from "gatsby"
import { loadStripe } from '@stripe/stripe-js'
import { useShoppingCart } from 'use-shopping-cart'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { InlineWidget } from "react-calendly"
import {
  Article,
  ArticleHeader,
  ArticleSection
} from "../styledComponents/article"
import LayoutNoStripe from "../components/layoutNoStripe"
import SignupIdentityForm from "../components/formIdentitySignup"
import SendGiftTo from "../components/formSendGiftTo";
import { StyledButton } from "../styledComponents/button"
import LoginRegisterBox from "../components/LoginRegisterBox"
import { analyticPurchase } from "../components/googleAnalytics"
import FormQuantumTouchRegister from "../components/formQuantumTouchRegister"

const Delayed = ({ children, waitBeforeShow = 3000 }) => {
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
  }, [waitBeforeShow]);
  return isShown ? children : null;
}

const stripe = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function insertDecimal(num) {
  return (num / 100).toFixed(2)
}

const EventBookingSuccess = ({ data, location }) => {
  const {
    clearCart
  } = useShoppingCart()
  const [customer , setCustomer] = useState({})
  const [customerFname , setCustomerFname] = useState({})
  const [customerLname , setCustomerLname] = useState({})
  const [customerEmail , setCustomerEmail] = useState({})
  const [session , setSession] = useState({})
  const [user , setUser] = useState({})
  const [products , setProducts] = useState([])
  const [roles , setRoles] = useState([])
  const [signingUp, setSigningUp] = useState(false)
  const { register, handleSubmit, errors, watch, setValue } = useForm()
  const [formProcessing, setFormProcessing] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formError, setFormError] = useState()
  const [userFullName, setUserFullName] = useState()
  const [userPhoneNumber, setUserPhoneNumber] = useState()
  const [eventType , setEventType] = useState({})
  const identity = useIdentityContext()
  const [processing, setProcessing] = useState(false)  
  const updateRoles = ({ add, remove }) => {
    setProcessing(true)
    identity.authorizedFetch('/api/update-role', {
      method: 'POST',
      body: JSON.stringify({
        action: add ? 'add' : 'remove',
        role: add || remove
      })
    })
      .then(identity.refreshUser)
      .finally(() => setProcessing(false))
  }

  const getStripeSession = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('sessionID') 
    if(myParam){
      fetch(`/.netlify/functions/stripe-customer?sessionID=${myParam}`)
      .then(response => response.json())
      .then((data) => {
        setSession(data.msg)
        setCustomer(data.customer)
        setProducts(data.productsList)
        let fullName = data.customer.name
        let firstName = fullName.split(' ').slice(0, -1).join(' ');
        let lastName = fullName.split(' ').slice(-1).join(' ');
        setCustomerEmail(data.customer.email)
        setCustomerFname(firstName)
        setCustomerLname(lastName)
        let meta_data = {};
        meta_data['FIRSTNAME'] = capitalizeFirstLetter(firstName)
        meta_data['LASTNAME'] = capitalizeFirstLetter(lastName)
        let contents = ''
        data.productsList.map(prod => {
          contents += prod.name + ' - '          
        })
        
        const value = data.msg.amount_total
        const re = /\b(\d+)(\d{2})\b/
        const str = value
        const subst = '$1.$2'
        const totalPriceWithDecimal = str.toString().replace(re, subst)
        const currency = 'gbp'
        const categoryName = !data.productsList[0].metadata.type ? 'empty' : data.productsList[0].metadata.type
        let totalWithDecimal
        totalWithDecimal = insertDecimal(data.msg.amount_total)
        analyticPurchase(
          'purchase', //action
          'event', //category
          data.productsList[0].name, //label
          data.msg.id, //transaction_id
          totalWithDecimal, //transaction_value
          data.msg.currency, //transaction_currency
          '0', //transaction_tax
          data.productsList[0].id, // itemid
          data.productsList[0].name, //item_name
          totalWithDecimal //item_price
        )
      })
    }
  }
  
  const onSubmit = async (data) => {
    setFormProcessing(true)
      await identity.update(
        {
          user_metadata: {
            roles: data.product
          }
        }
      )
      .then(_ => {
        updateRoles({ add: data.product })
        setFormProcessing(false)
        setFormSubmitted(true)
        navigate("/shop")
        setTimeout(() => (setFormSubmitted(false)), 2000)
      })
      .catch(e => {
        setFormProcessing(false)
        setFormError(e.message)
        setTimeout(() => (setFormError()), 5000)
        return
    })
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      getStripeSession()
      const urlParams = new URLSearchParams(window.location.search)
      const eventType = urlParams.get('eventType')
      setEventType(eventType)
      if(identity.user === undefined && roles.length === 0){
        setUser({...user,Signup: true})
      }else {
        setUser({...user,Signup: false})
      }
      if((identity.user && identity.user && !user.hasOwnProperty("id") ) || user.Signup)
      localStorage.removeItem('cart-values')
      return
    }
  }, [identity.user])

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader noMargin>
          <h1 itemProp="headline">Registration Success</h1>
        </ArticleHeader>
        <ArticleSection itemProp="articleBody">
              <Delayed>
              <p>Thank you {customerFname && customerFname} for registering onto the <strong>{products[0] && products[0].name}</strong>.</p>
              <p>Please can you fill in the following form, this is required from the Quantum-Touch head office.</p>
              <FormQuantumTouchRegister 
                eventType={eventType} 
                firstName={customerFname}
                lastName={customerLname} 
                email={customer.email}
                />
              </Delayed>
        </ArticleSection>
      </Article>
    </LayoutNoStripe>
  )
}

export default EventBookingSuccess

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
